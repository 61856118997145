import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCard, Pagination, TagHelper } from '../components/common'
import { MetaData } from '../components/common/meta'
import config from '../utils/siteConfig'
import { device } from "../utils/device.js"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import {HeaderXXL} from "../utils/typography"

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Posts = ({ data, location, pageContext }) => {
  console.log(data)
    const blog = data.allGhostPost.edges
    const tag = data.ghostTag
    const tags = data.allGhostTag.edges

    return (
        <>
            <MetaData location={location} data={data} type="series"/>
            <Layout isHome={true}>
              <CenteredContent>
                <TagHelper tags={tags}/>
                <IndexContainer>
                    <IndexHeader>
                      <h2>{tag.name}</h2>
                    </IndexHeader>
                    <Container >
                        {blog.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PostCard key={node.id} url={node.url} text={node.excerpt} title={node.title} img={node.localFeatureImage}/>
                        ))}
                    </Container>
                    </IndexContainer>
                    <Pagination pageContext={pageContext} />
              </CenteredContent>
            </Layout>
        </>
    )
}

const IndexContainer = styled("div")`
  margin: 2rem 0;
`

const IndexHeader = styled("div")`
  margin: -0.25em 0.2em;
  padding: 0;
  z-index: 10;
  position: relative;
  ${HeaderXXL}

  >h2{
    margin: 0;
    padding: 0;
    display: block;
    color: ${config.textColorDark};
  }
`

const Container = styled("div")`
  z-index: 0;
  width: 100%;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
`

const CenteredContent = styled("main")`
  min-height: 100vh;
  padding-top: 15vh;
  margin-left: auto;
  margin-right: auto;
  max-width: ${config.contentMaxWidth};
  width: 95%;

  @media ${device.desktop} {
    max-width: calc(${config.contentMaxWidth} * 1.2);
  }
`

export default Posts

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
query GhostPostByTagQuery($slug: String!, $limit: Int!, $skip: Int!) {
    ghostTag(slug: { eq: $slug }) {
        ...GhostTagFields
    }
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        filter: {tags: {elemMatch: {slug: {eq: $slug}}}},
        limit: $limit,
        skip: $skip
    ) {
        edges {
            node {
            url
            ...GhostPostFields
            localFeatureImage{
              childImageSharp {
                fluid(maxWidth: 1440, maxHeight: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            }
        }
    }
    allGhostTag(filter: {slug: {ne: $slug}}, sort: {order: DESC, fields: count___posts}) {
      edges {
        node {
          url
          slug
          name
          count {
            posts
          }
        }
      }
    }
}
`
